import { useEffect, useState } from "react";
import Message from "./Message";

const TestInfo = ({mode,active,children}) => {
    const [initcontent, setInitcontent] = useState("");
    useEffect(() => {
        if ((mode === 'test') && (active === 'y')) {
            setInitcontent(<Message messageType={'info'} message={children} />);
        } else if ((mode === 'test') && (active === null)) {
            setInitcontent(<Message messageType={'warning'} message={'To make a test payment you must first sign-in and provide Test/Sandbox API details for this payment gateway.'} />);
        } else {
            setInitcontent("");
        }
    },[mode,active,children]);
    return (initcontent);
}

export default TestInfo;