import './Message.css';

const Message = ({messageType,message}) => {
    const msgtype = messageType;
    const msg = message;
    switch (msgtype) {
        case 'error':
            return(
                <div className='error-msg'><i className='fa fa-times-circle'></i> {msg}</div>
            );
        case 'info':
            return(
                <div className='info-msg'><i className='fa fa-info-circle'></i> {msg}</div>
            );    
        case 'success':
            return(
                <div className='success-msg'><i className='fa fa-check'></i> {msg}</div>
            );    
        case 'warning':
            return(
                <div className='warning-msg'><i className='fa fa-warning'></i> {msg}</div>
            );    
        default:
            return(
                <h1>Invalid Message Type</h1>
            );
    }
}

export default Message;