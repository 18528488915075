import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from "react-cookie";
import './index.css';
import App from './App';
import PayApp from './PayApp';
import Message from './Message';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const path = window.location.pathname;
const pathArray = path.split('/');

if (path === '/') {
  root.render(
    <React.StrictMode>
      <CookiesProvider><App /></CookiesProvider>
    </React.StrictMode>
  );
} else {
  (pathArray[4]) ?
  root.render(
    <React.StrictMode>
      <CookiesProvider><PayApp pgId={pathArray[1]} customMsg={pathArray[2]} mode={pathArray[3]} userId={pathArray[4]} /></CookiesProvider>
    </React.StrictMode>
  ) : 
  (pathArray[3]) ? 
  root.render(
    <React.StrictMode>
      <Message messageType={'error'} message={'Invalid link format'} />
      <CookiesProvider><PayApp pgId={pathArray[1]} customMsg={pathArray[2]} /></CookiesProvider>
    </React.StrictMode>
  ) : 
  (pathArray[2]) ? 
  root.render(
    <React.StrictMode>
      <CookiesProvider><PayApp pgId={pathArray[1]} customMsg={pathArray[2]} /></CookiesProvider>
    </React.StrictMode>
  ) : 
  root.render(
    <React.StrictMode>
      <CookiesProvider><PayApp pgId={pathArray[1]} /></CookiesProvider>
    </React.StrictMode>
  ); 

}

// const btn = ReactDOM.createRoot(document.getElementById('btn'));
// btn.render(
//   <React.StrictMode>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
