import IndianBank from "./IndianBank";

const IndiaBankButton = ({data,text,children}) => {
    const clickHandler = () => {
        document.getElementById("indianBank").style.display = "block";
        document.getElementById("indianBankButton").style.display = "none";
    }
    return(
        <>
        <div>
            <span className="small_text">{text}</span><br />
            <button id="indianBankButton" onClick={clickHandler}>{children}</button>
        </div>
        <div id="indianBank">
            <IndianBank data={data} />
        </div>
        </>
    );
}

export default IndiaBankButton;