import { useEffect, useRef, useState } from "react";
import Message from "./Message";
import ItemDetail from "./ItemDetail";
import PayDetail from "./PayDetail";
import SellerDetail from "./SellerDetail";
import Footer from "./Footer";
import './PayApp.css';
import loader from './images/fb-1s-80px.gif';
import { getApiData } from './data.js';

const PayApp = ({pgId,customMsg,mode,userId}) => {
  window.sessionStorage.setItem('pgid', pgId);
  window.sessionStorage.setItem('cMsg', customMsg);
  window.sessionStorage.setItem('md', mode);
  window.sessionStorage.setItem('user', userId);
  const apiFetch = useRef("");
  //Display a loading image initially
  const [initcontent, setInitcontent] = useState(    
    <div className="section">
      <img src={loader} className="loaderimg" alt="loading..." />
    </div>
  );
  //API URL for fetching data
  let api_url = "https://paymypage.com/buy/pay2api.php?id="+pgId+"&c="+customMsg;
  if (mode && (mode === 'test')) {
    api_url = api_url+"&m=test&s="+userId;
  }
  useEffect(() => {
    // Calling that async function
    apiFetch.current = getApiData(api_url);
    if (apiFetch.current === false) {
      setInitcontent(<Message messageType={'error'} message={'Error fetching data'} />);
    } else {
      //Extract promise data
      apiFetch.current.then((result) => {
          let {data} = result;
          let [{message}] = data;
          if (message) {
            setInitcontent(<Message messageType={'error'} message={message} />);
          } else {
            setInitcontent( 
                <div className='section'>
                  <div className='display-area-1'>
                    <SellerDetail data={data[0]} />
                    <ItemDetail data={data[0]} />
                  </div>
                  <div className='display-area-2'>
                    <PayDetail data={data[0]} />
                  </div>
                  <div className='footer'>
                    <Footer data={data[0]} />
                  </div>
                </div>
            );
          }
      });
    }

    //Cleanup function
    return () => window.sessionStorage.clear();
  }, [api_url]);

  return (initcontent);
}

export default PayApp;