const ItemDetail = ({data}) => {
    const {item_title,item_image,item_desc} = data;
    return(
        <>
        <div className='item-title'>
        {item_title}
        </div>
        {(item_image) ?
        <div><img className='item_image' src={item_image} alt='' /></div> : ""}
        <div className='item-desc'>
        {item_desc}
        </div>
        </>
    );
}

export default ItemDetail;