import logo from './images/paymypage-logo-600x135.png';
import './App.css';
import LinkButton from './LinkButton';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Simplified Payment Integration For Websites
        </p>
        <LinkButton link='https://paymypage.com/?pay2-home'>
          Know More
        </LinkButton>
      </header>
    </div>
  );
}

export default App;