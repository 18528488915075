import Ifsc from "./Ifsc";
import Vpa from "./Vpa";

const IndianBank = ({data}) => {
    return (
        <>
            <Vpa data={data} />
            <Ifsc data={data} />
        </>
    );
}

export default IndianBank;