const LinkButton = ({link,text,children}) => {
    const clickHandler = () => {
        window.location.replace(link);
    }
    return(
        <>
        <div>
            <span className="small_text">{text}</span><br />
            <button onClick={clickHandler}>{children}</button>
        </div>
        </>
    );
}

export default LinkButton;