import { useEffect, useState } from "react";

const Ifsc = ({data}) => {
    let customMsg = window.sessionStorage.getItem('cMsg');
    if (customMsg === "undefined") {
        customMsg = "";
    }
    const {item_title,inr_price,bank_in_actype,bank_in_acholder,bank_in_acno,bank_in_ifsc} = data;
    const [initcontent, setInitcontent] = useState("");
    useEffect(() => {
        if (customMsg) {
            setInitcontent(
                <>
                    <fieldset>
                        <div className="item-desc">
                            <span className="pay_opts_title">Pay via NEFT/IMPS</span><br /><br />
                            Amount to pay:<br />
                            <strong>{inr_price}</strong><br /><br />
                            Beneficiary:<br />
                            <strong>{bank_in_acholder}</strong><br /><br />
                            Account Type:<br />
                            <strong>{bank_in_actype}</strong><br /><br />
                            Account no.:<br />
                            <strong>{bank_in_acno}</strong><br /><br />
                            Bank IFSC:<br />
                            <strong>{bank_in_ifsc}</strong><br /><br />
                            Mention this in Transfer Description:<br />
                            <strong>{customMsg}</strong>
                        </div>
                    </fieldset>
                </>
            );
        } else {
            setInitcontent(
                <>
                    <fieldset>
                        <div className="item-desc">
                            <span className="pay_opts_title">Pay via NEFT/IMPS</span><br /><br />
                            Amount to pay:<br />
                            <strong>{inr_price}</strong><br /><br />
                            Beneficiary:<br />
                            <strong>{bank_in_acholder}</strong><br /><br />
                            Account Type:<br />
                            <strong>{bank_in_actype}</strong><br /><br />
                            Account no.:<br />
                            <strong>{bank_in_acno}</strong><br /><br />
                            Bank IFSC:<br />
                            <strong>{bank_in_ifsc}</strong><br /><br />
                            Mention this in Transfer Description:<br />
                            <strong>{item_title}</strong>
                        </div>
                    </fieldset>
                </>
            );
        }
    },[customMsg,inr_price,bank_in_actype,bank_in_acholder,bank_in_acno,bank_in_ifsc,item_title]);
    return (initcontent);
}

export default Ifsc;