import { useEffect, useState } from "react";

const Footer = ({data}) => {
    const {ads,user_name,user_company_name} = data;
    const [name, setName] = useState("");
    const [initcontent, setInitcontent] = useState("");
    useEffect(() => {
        (user_company_name) ? setName(user_company_name) : setName(user_name)
        if (ads === 'show') {
            setInitcontent(
                <>
                    <div className="small_text centered_text">
                        We use cookies to ensure continuity between payment processors and our payment pages. Disabling cookies may affect payment functionality.<br />
                        Content submitted by {name}  has not been moderated in any way.<br />
                        Payment page for {name} powered by <a href="https://paymypage.com/?paymentpagelink" target="_blank" rel="noopener noreferrer">payMyPage</a>, an iWebz service.<br />
                        <a href="https://www.trustedsite.com/verify?host=paymypage.com" target="_blank" rel="noreferrer noopener nofollow"><img className="tsm_badge" src="https://cdn.ywxi.net/meter/trustedsite.com/202.svg?ts=1661432196740&l=en-IN" alt="TrustedSite Certificate Secure badge" /></a>
                    </div>
                </>
            );
        } else {
            setInitcontent(
                <>
                    <div className="small_text centered_text">
                        We use cookies to ensure continuity between payment processors and our payment pages. Disabling cookies may affect payment functionality.<br />
                        Content submitted by {name}  has not been moderated in any way.<br />
                        Payment page for {name} powered by <a href="https://paymypage.com/?paymentpagelink" target="_blank" rel="noopener noreferrer">payMyPage</a>, an iWebz service.<br />
                        <a href="https://www.trustedsite.com/verify?host=paymypage.com" target="_blank" rel="noreferrer noopener nofollow"><img className="tsm_badge" src="https://cdn.ywxi.net/meter/trustedsite.com/202.svg?ts=1661432196740&l=en-IN" alt="TrustedSite Certificate Secure badge" /></a>
                    </div>
                </>
            );
        }
    },[ads,name,user_name,user_company_name]);
    return (initcontent);
}

export default Footer;