import { useCookies } from 'react-cookie';
import LinkButton from "./LinkButton";
import { Base64 } from 'js-base64';
import TestInfo from "./TestInfo";
import IndiaBankButton from "./IndiaBankButton";
import Message from "./Message";

const PayDetail = ({data}) => {
  const {txn_id,user_country,user_bank_in,user_paytm,user_cf,user_imojo,user_paypal,user_bitpay,user_tap,user_fwave,visitor,visitor_country,visitor_country_code,inr_price,usd_price,kes_price,ngn_price,ugx_price,zar_price,sar_price,qar_price,bhd_price,kwd_price,aed_price,omr_price,egp_price,ty_note,mod_paytm,mod_cf,mod_paypal,mod_bitpay,mod_bank_in,mod_imojo,mod_tap,mod_fwave,paypal_test,bank_in_test,cf_test,paytm_test,imojo_test,bitpay_test,fwave_test,tap_test} = data;
  const pgId = window.sessionStorage.getItem('pgid');
  const customMsg = window.sessionStorage.getItem('cMsg');
  const mode = window.sessionStorage.getItem('md');
  const [cookies,setCookie] = useCookies(['visitor']);
  const urlParams = new URLSearchParams(window.location.search);
  const payId = urlParams.get('payment_id');
  const pgMod = urlParams.get('module');
  if (payId) {
    if (ty_note) {
      return(
          <>
            <div className="item_pay_opts">
              <Message messageType={'success'} message={ty_note} />
              <Message messageType={'info'} message={`Payment ID: ${payId} | Gateway: ${pgMod}`} />
            </div>
          </>
      );
    } else {
      return(
          <>
            <div className="item_pay_opts">
              <Message messageType={'success'} message={'Your payment has been received! If you need to get in touch, use the contact info at the start of this page.'} />
              <Message messageType={'info'} message={`Payment ID: ${payId} | Gateway: ${pgMod}`} />
            </div>
          </>
      );
    }
  } else {
    let vid = visitor;
    (localStorage.getItem('vid')) ? vid = localStorage.getItem('vid') : localStorage.setItem('vid',vid)
    let expires = new Date();
    expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
    (cookies.vid) ? 
    (vid === cookies.vid) ? vid = cookies.vid : setCookie('vid', vid, { path: '/', expires })
    :  setCookie('vid', vid, { path: '/', expires })
    return(
        <>
          <div className="pay_opts_title">
            Payment options for {visitor_country}
          </div>
          <div className="item_pay_opts">
            {((inr_price.split(" ")[1] !== '0.00') && (mod_paytm === 'y') && (user_paytm === 'y') && (visitor_country_code === 'IN')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('paytm')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Debit/Credit cards, Net Banking, UPI, Digital Wallets, EMI, and Paytm Postpaid'} >Pay {inr_price}</LinkButton><TestInfo mode={mode} active={paytm_test} >For testing Paytm payments use the test card and bank details mentioned <a href='https://business.paytm.com/docs/test-instruments/testing-integration/' target='_blank' rel='noreferrer noopener'>here</a></TestInfo></> : ""}
            {((inr_price.split(" ")[1] !== '0.00') && (mod_cf === 'y') && (user_cf === 'y') && (visitor_country_code === 'IN')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('cf')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Credit & Debit Cards, NetBanking, UPI, Digital Wallets'} >Pay {inr_price}</LinkButton><TestInfo mode={mode} active={cf_test} >For testing CashFree payments use the test card and bank details mentioned <a href='https://docs.cashfree.com/docs/data-to-test-integration' target='_blank' rel='noreferrer noopener'>here</a></TestInfo></> : ""}
            {((inr_price.split(" ")[1] !== '0.00') && (mod_imojo === 'y') && (user_imojo === 'y') && (visitor_country_code === 'IN')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('imojo')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Credit & Debit Cards, NetBanking, UPI, Digital Wallets'}>Pay {inr_price}</LinkButton><TestInfo mode={mode} active={imojo_test} >For testing Instamojo payments use the card details on <a href='https://support.instamojo.com/hc/en-us/articles/208485675-Test-or-Sandbox-Account' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((inr_price.split(" ")[1] !== '0.00') && (mod_bank_in === 'y') && (user_bank_in === 'y') && (visitor_country_code === 'IN')) ? 
            <><IndiaBankButton data={data} text={'Indian Offline Bank payment options'}>Pay {inr_price}</IndiaBankButton><TestInfo mode={mode} active={bank_in_test} >The Indian Bank Transfer module does not have a test mode.</TestInfo></> : ""}
            {((usd_price.split(" ")[1] !== '0.00') && (mod_paypal === 'y') && (user_paypal === 'y') && (visitor_country_code === 'IN') && (user_country !== 'india')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('paypal')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'International Cards'} >Pay {usd_price}</LinkButton><TestInfo mode={mode} active={paypal_test} >Click <a href='https://developer.paypal.com/developer/accounts/' target='_blank' rel='noreferrer noopener'>here</a> to login & view sandbox (test) accounts. Then click on <em>Profile</em> for your test Buyer account and see the test payment details on the <em>Funding</em> tab.</TestInfo></> : ""}
            {((usd_price.split(" ")[1] !== '0.00') && (mod_paypal === 'y') && (user_paypal === 'y') && (visitor_country_code !== 'IN') && (user_country === 'india')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('paypal')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'PayPal or International Cards'} >Pay {usd_price}</LinkButton><TestInfo mode={mode} active={paypal_test} >Click <a href='https://developer.paypal.com/developer/accounts/' target='_blank' rel='noreferrer noopener'>here</a> to login & view sandbox (test) accounts. Then click on <em>Profile</em> for your test Buyer account and see the test payment details on the <em>Funding</em> tab.</TestInfo></> : ""}
            {((usd_price.split(" ")[1] !== '0.00') && (mod_bitpay === 'y') && (user_bitpay === 'y')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('bitpay')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Bitcoin and other cryptocurrencies'}>Pay {usd_price}</LinkButton><TestInfo mode={mode} active={bitpay_test} >For testing bitpay payments follow the instructions for test merchant account and test wallet on <a href='https://support.bitpay.com/hc/en-us/articles/115003001083-Can-I-test-the-service-without-signing-up-for-a-live-account-' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((aed_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'AE')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard'}>Pay {aed_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((egp_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'EG')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard'}>Pay {egp_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((sar_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'SA')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard, MADA'}>Pay {sar_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((bhd_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'BH')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard, BENEFIT'}>Pay {bhd_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((omr_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'OM')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard'}>Pay {omr_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((qar_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'QA')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard'}>Pay {qar_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((kwd_price.split(" ")[1] !== '0.00') && (mod_tap === 'y') && (user_tap === 'y') && (visitor_country_code === 'KW')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard, KNET'}>Pay {kwd_price}</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {(((aed_price.split(" ")[1] !== '0.00') || (sar_price.split(" ")[1] !== '0.00') || (qar_price.split(" ")[1] !== '0.00') || (omr_price.split(" ")[1] !== '0.00') || (egp_price.split(" ")[1] !== '0.00') || (bhd_price.split(" ")[1] !== '0.00') || (kwd_price.split(" ")[1] !== '0.00')) && (mod_tap === 'y') && (user_tap === 'y')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('tap')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'VISA/Mastercard'}>Pay Now</LinkButton><TestInfo mode={mode} active={tap_test} >For testing Tap payments use the card details on <a href='https://tappayments.api-docs.io/2.0/testing/test-card-numbers' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((ngn_price.split(" ")[1] !== '0.00') && (mod_fwave === 'y') && (user_tap === 'y') && (visitor_country_code === 'NG')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('fwave')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Cards, USSD, Mobile Money, Bank Transfers'}>Pay {ngn_price}</LinkButton><TestInfo mode={mode} active={fwave_test} >For testing Flutterwave payments use the card details on <a href='https://developer.flutterwave.com/docs/test-cards' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((ugx_price.split(" ")[1] !== '0.00') && (mod_fwave === 'y') && (user_tap === 'y') && (visitor_country_code === 'UG')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('fwave')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Cards, Mobile Money'}>Pay {ugx_price}</LinkButton><TestInfo mode={mode} active={fwave_test} >For testing Flutterwave payments use the card details on <a href='https://developer.flutterwave.com/docs/test-cards' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((kes_price.split(" ")[1] !== '0.00') && (mod_fwave === 'y') && (user_tap === 'y') && (visitor_country_code === 'KE')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('fwave')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Cards, M-Pesa'}>Pay {kes_price}</LinkButton><TestInfo mode={mode} active={fwave_test} >For testing Flutterwave payments use the card details on <a href='https://developer.flutterwave.com/docs/test-cards' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {((zar_price.split(" ")[1] !== '0.00') && (mod_fwave === 'y') && (user_tap === 'y') && (visitor_country_code === 'ZA')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('fwave')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'Cards, ACH'}>Pay {zar_price}</LinkButton><TestInfo mode={mode} active={fwave_test} >For testing Flutterwave payments use the card details on <a href='https://developer.flutterwave.com/docs/test-cards' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
            {(((ngn_price.split(" ")[1] !== '0.00') || (ugx_price.split(" ")[1] !== '0.00') || (kes_price.split(" ")[1] !== '0.00') || (zar_price.split(" ")[1] !== '0.00')) && (mod_fwave === 'y') && (user_fwave === 'y')) ? 
            <><LinkButton link={`https://paymypage.com/buy/pay.php?mod=${Base64.encode('fwave')}&id=${pgId}&oid=${txn_id}&vid=${visitor}&mode=${mode}&c=${customMsg}`} text={'International Cards'}>Pay Now</LinkButton><TestInfo mode={mode} active={fwave_test} >For testing Flutterwave payments use the card details on <a href='https://developer.flutterwave.com/docs/test-cards' target='_blank' rel='noreferrer noopener'>this page</a>.</TestInfo></> : ""}
          </div>
        </>
    );
  }
}

export default PayDetail;