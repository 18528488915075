const SellerDetail = ({data}) => {
  const {user_name,user_company_name,user_logo,user_phone,user_email,user_website,user_about,user_terms_link,user_privacy_link} = data;
  return(
      <>
        <div className="user_details">
          <div className="user_logo">
            {(user_logo) ? <img className='user_image' src={user_logo} alt='logo' /> : ""}
          </div>
          <div className="user_info">
              <div><strong>{(user_company_name) ? user_company_name : user_name}</strong></div>
              <div>
                {(user_phone) ? <span>{user_phone} | </span> : ""}
                {(user_email) ? <span>{user_email} | </span> : ""}
                {(user_website) ? <span><a href={user_website} target='_blank' rel='nofollow noreferrer noopener'>Online Helpdesk</a> | </span> : ""}
                {(user_terms_link) ? <span><a href={user_terms_link} target='_blank' rel='nofollow noreferrer noopener'>Terms</a> | </span> : ""}
                {(user_privacy_link) ? <span><a href={user_privacy_link} target='_blank' rel='nofollow noreferrer noopener'>Privacy</a></span> : ""}
              </div>
              <div>
                {(user_about) ? user_about : ""}
              </div>
          </div>
        </div>
      </>
  );
}

export default SellerDetail;