import { useEffect, useState } from "react";

const Vpa = ({data}) => {
    let customMsg = window.sessionStorage.getItem('cMsg');
    if (customMsg === "undefined") {
        customMsg = "";
    }
    const {user_company_name,user_name,txn_id,item_title,inr_price,bank_in_qr,bank_in_vpa} = data;
    //Build VPA link
    let nom;
    (user_company_name) ? nom = user_company_name : nom = user_name;
    let vpa_link = "upi://pay?pn="+encodeURI(nom)+"&pa="+bank_in_vpa+"&cu=INR&tr="+txn_id;
    if (customMsg) {
        vpa_link = vpa_link+"&tn="+customMsg+"&purpose="+customMsg;
    } else {
        vpa_link = vpa_link+"&tn="+encodeURI(item_title)+"&purpose="+encodeURI(item_title);
    }
    const priceStringArr = inr_price.split(" ");
    vpa_link = vpa_link+"&am="+priceStringArr[1];
    const [initcontent, setInitcontent] = useState("");
    useEffect(() => {
        if (bank_in_vpa && customMsg) {
            setInitcontent(
                <>
                    <fieldset className="center_text">
                        <div className="item-desc">
                            <span className="pay_opts_title">Pay via BHIM/UPI</span>
                            <p>Tap the QR code and Open using UPI mobile app like BHIM, Paytm, Amazon Pay, GPay, PhonePe, etc.</p>
                            <a href={vpa_link}>
                                <img className="qr" src={bank_in_qr} alt="" />
                            </a><br /><br />
                            Paying to:<br />
                            <strong>{bank_in_vpa}</strong><br />
                            Amount to pay:<br />
                            <strong>{inr_price}</strong><br  />
                            Mention this in Remarks/Note:<br />
                            <strong>{customMsg}</strong>
                        </div>
                    </fieldset>
                </>
            );
        } else if (bank_in_vpa && (customMsg === "")) {
            setInitcontent(
                <>
                    <fieldset className="center_text">
                        <div className="item-desc">
                            <span className="pay_opts_title">Pay via BHIM/UPI</span>
                            <p>Tap the QR code and Open using UPI mobile app like BHIM, Paytm, Amazon Pay, GPay, PhonePe, etc.</p>
                            <a href={vpa_link}>
                                <img className="qr" src={bank_in_qr} alt="" />
                            </a><br /><br />
                            Paying to:<br />
                            <strong>{bank_in_vpa}</strong><br />
                            Amount to pay:<br />
                            <strong>{inr_price}</strong><br  />
                            Mention this in Remarks/Note:<br />
                            <strong>{item_title}</strong>
                        </div>
                    </fieldset>
                </>
            );
        } else {
            setInitcontent("");
        }
    },[customMsg,item_title,inr_price,bank_in_qr,bank_in_vpa,vpa_link]);
    return (initcontent);
}

export default Vpa;