// Defining async function
export async function getApiData(url) {
    //Fetch data with error processing
    try {
        // Storing response
        const response = await fetch(url);
        // Storing data in form of JSON
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        return false;
    }
}

// Defining async function
export async function postApiData(url, body) {
    //Fetch data with error processing
    try {
        // Posting 'body' object and storing the response
        const response = await fetch(url,{
            method: "POST",
            body: JSON.stringify(body)
        });
        // Storing data in form of JSON
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        return false;
    }
}

